<template>
  <div class="pdf-view-page">
    <div class="container">
      <div class="my-4 text-center">
        <h1 style="font-size: 2rem;">{{ $route.meta.title }}</h1>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <span v-if="isLoading">Loading...</span>
        </div>
        <div class="col-6 text-right">
          <a :href="pdfSource" target="_blank" style="float: right;">
            <font-awesome-icon class="va-m" icon="download"></font-awesome-icon>
            {{ $route.meta.title }}をダウンロード
          </a>
        </div>
      </div>
      <div class="pdf-view-wrapper">
        <vue-pdf-embed
          ref="pdfRef"
          :source="pdfSource"
          @rendered="handleDocumentRender"
        />
      </div>
    </div>
  </div>
</template>

<script>
// BUG2023-2043
import CompanyInfo from '@/model/CompanyInfo.js';
import FooterSetting from '@/model/FooterSetting.js';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      pdfSource: null,
      isLoading: true,
    }
  },
  created() {
    this.$route.meta.title = this.$route.query.title;
  },
  mounted() {
    FooterSetting
      .getFooterSettingById(this.$store.state.user.companyCode, this.$route.query.footerSettingId)
      .then(res => {
        if (res.data && res.data.fileDtoList.length > 0) {
          CompanyInfo
            .getDownloadFileLink(this.$store.state.user.companyCode, res.data.fileDtoList[0].filePath, res.data.fileDtoList[0].fileName)
            .then(response => {
              this.pdfSource = response.data.data.url;
            });
        } else {
          this.$router.replace({ name: "PageNotFound" });
        }
      });
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
    },
  },
}
</script>

<style>
.pdf-view-wrapper {
  border: 2px solid #ccc;
  height: 500px;
  overflow-y: scroll;
}
</style>
