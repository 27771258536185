import Model from './_base';

export default class FooterSetting extends Model {

    static entity = 'footer-setting';
    
    constructor() {
        super(FooterSetting.entity);
    }

    // BUG2023-2043
    static getFooterSettingList(companyCode) {
        return Model.axios.get(this.url('/' + companyCode + '/get-footer-setting-list'));
    }
    // BUG2023-2043
    static getFooterSettingById(companyCode, id) {
        return Model.axios.get(this.url('/' + companyCode + '/get-footer-setting-by-id?id=' + id));
    }
}